import React from "react";
import styles from "../../styles/info-site/GetStarted.module.css";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Cta from "../styleguide/Cta";

import Logo from "../../assets/simple-logo.png"

const defaultTrusted = [ "getStartedTrustItemOne", "getStartedTrustItemTwo", "getStartedTrustItemThree"]

const GetStarted = ({
  ctaText = "requestDemo",
  trustItems = defaultTrusted,
  subtitle = "getStartedDescription",
  title = "getStartedTitle",
  light = false
}) => {
    // const bookADemo = () => {
    //     window.location.href = "https://rtbmedia.hubspotpagebuilder.com/book-a-demo";
    // };

    return (
        <div className={`${styles.GetStarted} ${light ? styles.GetStarted_light : ''}`}>
            <span className={styles.GetStartedTitle}><FormattedMessage id={title} /></span>
            <p><FormattedHTMLMessage id={subtitle} /></p>
            {/* <Cta className={styles.GetStartedCta} onClick={bookADemo} text={ctaText} /> */}
            <Cta className={styles.GetStartedCta} link='/request-demo' text={ctaText} />
            {
              trustItems?.length > 0 && (
                <div className={styles.GetStartedTrustItems}>
                  {
                    trustItems.map(item => (
                      <span key={item.replace(/s/g, '').toLocaleLowerCase()} className={styles.GetStartedTrustItem}><FormattedMessage id={item} /></span>
                    ))
                  }
                </div>
              )
            }
            <img className={`${styles.GetStartedLogo} ${styles.GetStartedLogo__left}`} src={Logo} alt="" />
            <img className={`${styles.GetStartedLogo} ${styles.GetStartedLogo__right}`} src={Logo} alt="" />
        </div>
    );
};

export default GetStarted;