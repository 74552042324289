import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from 'react-intl'

import styles from '../../styles/info-site/PressLinks.module.css'

const PressLinks = ({ title, description, cards }) => {
  return (
    <div className={styles.PressLinks}>
      <h2>
        <FormattedMessage id={title} />
      </h2>
      <p>
        <FormattedMessage id={description} />
      </p>

      {
        cards?.length && (
          <ul className={styles.PressLinksList}>
            {
              cards.map(({ id, date, url, desc, self }) => (
                <li key={id} className={styles.PressLinksListItem}>
                  <a href={url} target={self || "_blank"} rel="noreferrer">
                    <span className={styles.PressLinksDate}>{date}</span>
                    <h3><FormattedMessage id={desc} /></h3>
                    <span className={styles.PressLinksIcon}>
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </span>
                  </a>
                </li>
              ))
            }
          </ul>
        )
      }
    </div>
  )
}

export default PressLinks