import React from "react";
import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import PressLinks from "../../components/info-site/PressLinks";
import GetStarted from "../../components/info-site/GetStarted";

import cards from "../../data/pressLinks"

const PressLinksPage = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="pressLinksTitle" descriptionId="pressLinksDescription" />

            <PressLinks
              title="pressLinks"
              description="pressLinksDescription"
              cards={cards}
            />
            <GetStarted
              title="joinUsNews"
            />
        </Layout>
    );
};

export default PressLinksPage;
